<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Seat Management</h3>
            </div>
            <div class="card-body">
              <div class="row1">
                <card shadow type="secondary">
                  <form>
                    <div class="pl-lg-4">
                      <div class="row">
                        <div class="col-lg-3">
                          <base-input label="Room">
                            <el-select
                              style="width: 100%"
                              v-model="model.room"
                              placeholder="Room"
                            >
                              <el-option
                                v-for="option in manageroomData"
                                :value="option.id"
                                :label="option.name"
                                :key="option.id"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="col-lg-3">
                          <base-input
                            alternative=""
                            label="Name"
                            placeholder="Name"
                            input-classes="form-control-alternative"
                            @input="
                              (event) => (model.name = event.target.value)
                            "
                          />
                        </div>
                        <div class="col-lg-3">
                          <base-input label="Owner Name">
                            <el-select
                              style="width: 100%"
                              v-model="model.owner_name"
                              placeholder=""
                            >
                              <el-option
                                v-for="option in memberData"
                                :value="option.id"
                                :label="option.full_name"
                                :key="option.id"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                        </div>
                        <div class="col-lg-3">
                          <base-input label="Occupier Name">
                            <el-select
                              style="width: 100%"
                              v-model="model.occupier_name"
                              placeholder=""
                            >
                              <el-option
                                v-for="option in memberData"
                                :value="option.id"
                                :label="option.full_name"
                                :key="option.id"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="Search()"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </card>
                <br />
                <card>
                  <div class="row1">
                    <div class="table-responsive">
                      <base-table
                        class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark' : ''"
                        :thead-classes="
                          type === 'dark' ? 'thead-dark' : 'thead-light'
                        "
                        tbody-classes="list"
                        :data="seatManagement"
                      >
                        <template v-slot:columns>
                          <th>Name</th>
                          <th>Owner Name</th>
                          <th>Occupier Name</th>
                          <th>Price($)</th>
                        </template>

                        <template v-slot:default="row">
                          <td class="budget">
                            <base-input
                              alternative=""
                              placeholder=""
                              input-classes="form-control-alternative"
                              @input="
                                (event) => (row.item.code = event.target.value)
                              "
                              :value="row.item.code"
                            />
                          </td>
                          <td class="budget">
                            <base-input>
                              <el-select
                                style="width: 100%"
                                v-model="row.item.owner_name"
                                placeholder=""
                              >
                                <el-option
                                  v-for="option in memberData"
                                  :value="option.id"
                                  :label="option.full_name"
                                  :key="option.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </td>
                          <td class="budget">
                            <base-input>
                              <el-select
                                style="width: 100%"
                                v-model="row.item.occupier_name"
                                placeholder=""
                              >
                                <el-option
                                  v-for="option in memberData"
                                  :value="option.id"
                                  :label="option.full_name"
                                  :key="option.id"
                                >
                                </el-option>
                              </el-select>
                            </base-input>
                          </td>
                          <td class="budget">
                            <base-input
                              alternative=""
                              placeholder=""
                              input-classes="form-control-alternative"
                              @input="
                                (event) => (row.item.price = event.target.value)
                              "
                              :value="row.item.price"
                            />
                          </td>
                        </template>
                      </base-table>
                    </div>

                    <div
                      class="card-footer d-flex justify-content-end"
                      :class="type === 'dark' ? 'bg-transparent' : ''"
                    >
                      <!-- <base-pagination total="30"></base-pagination> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5"></div>
                    <div class="col-md-4">
                      <button
                        type="button"
                        class="btn btn-default"
                        v-on:click="create()"
                      >
                        Create
                      </button>

                      <button
                        type="button"
                        class="btn btn-default"
                        @click="printtable()"
                      >
                        <i class="fa fa-print mr-2" size="5x"></i>
                        Print
                      </button>
                    </div>
                  </div>
                  <div v-if="isLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Created
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElSelect, ElOption } from "element-plus";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const manageroomRepository = RepositoryFactory.get("manageroom");
const seatRepository = RepositoryFactory.get("seat");
const memberRepository = RepositoryFactory.get("member");
export default {
  name: "Payment",
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  data() {
    return {
      isLoading: false,
      manageroomData: [],
      model: {},
      seatManagement: [],
      memberData: [],
    };
  },
  methods: {
    printtable() {
      window.print();
    },

    async create() {
      this.model.seatManagement = this.seatManagement;
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await seatRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 3000);
      }
    },
    async getList() {
      this.tableData = await manageroomRepository.getManageroom();
      this.manageroomData = this.tableData.data;
    },
    async getMemberList() {
      this.tableData = await memberRepository.getMember();
      this.memberData = this.tableData.data;
    },

    numarray(number) {
      var array = [];
      for (let index = 0; index <= number; index++) {
        array[index] = index;
      }

      return array;
    },
    pad(n, width, z) {
      let number = width;
      if (number <= 9999) {
        number = ("000" + number).slice(-4);
      }
      return n + number + z;
    },

    async Search() {
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await seatRepository.searchSeat(data);

      if (response.data.length == 0) {
        this.seatManagement = [];
      }
      if (response.data.length != 0) {
        this.seatManagement = response.data;
      } else {
        if (
          this.model.room != null &&
          this.model.full_name == null &&
          this.model.owner_name == null &&
          this.model.occupier_name == null
        ) {
          this.seatManagement = [];
          let data = await manageroomRepository.getManageroomByid(
            this.model.room
          );

          if (data.data.length != 0) {
            let seatCode = data.data[0].seat;
            for (
              let index2 = 1;
              index2 < this.numarray(seatCode).length;
              index2++
            ) {
              this.seatManagement.push({
                code: data.data[0].code + this.pad(0, index2, ""),
                price: data.data[0].price,
                occupier_name: data.data[0].occupier_name ? null : null,
                owner_name: data.data[0].owner_name ? null : null,
              });
            }
          }
        }
      }
    },
  },
  async mounted() {
    await this.getMemberList();
    await this.getList();
  },
};
</script>

<style></style>
